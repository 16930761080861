import _sortBy from 'lodash/sortBy';
import React from 'react';
import Helmet from 'react-helmet';

import ImageColumn from '../components/ImageColumn';
import Layout from '../components/Layout';
import Overview from '../components/Overview';
import ProjectText from '../components/ProjectText';

const Project = ({ pageContext }) => {
    const { siteTitle, name, photos, allProjects, type, textLeft, textRight, textMeta } = pageContext

    return (
        <Layout>
            {textLeft && (
                <Helmet
                    title={`${siteTitle} - ${name}`}
                    meta={[
                        { name: 'description', content: textLeft.textLeft.split('\n').join('. ') },
                        { name: 'keywords', content: 'Kaspar, Achenbach, Fotograf, Köln, Kameramann' }
                    ]}
                />
            )}

            <ImageColumn images={_sortBy(photos, photo => photo.file.fileName)} />

            <ProjectText textLeft={textLeft} textRight={textRight} textMeta={textMeta} />
        </Layout>
    )
}


export default Project
