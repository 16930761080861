import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImageColumn = ({ images }) => (
    <div className="ImageColumn">
        {images.map((image, index) => (
            <GatsbyImage alt="" className="ImageColumn__image" image={getImage(image.localFile.childImageSharp.gatsbyImageData)} key={index} />
        ))}
    </div>
)

export default ImageColumn
