import React, { Component } from 'react';

class ProjectText extends Component {
    render() {
        const { textLeft, textRight, textMeta } = this.props

        return (
            <div className="ProjectText">
                {textLeft && <div className="ProjectText__text">{textLeft.textLeft}</div>}
                {textRight && <div className="ProjectText__text ProjectText__text--right">{textRight.textRight}</div>}
                {textMeta && <div className="ProjectText__text ProjectText__text--meta">{textMeta.textMeta}</div>}
            </div>
        )
    }
}


export default ProjectText
